.admin_dashboard {
	.card-body {
		padding: 30px 30px 0px 30px;
		.icon-big {
			.material-icons {
				font-size: 4em;
				min-height: 64px;
			}
		}
		.numbers {
			font-size: 1.8rem;
			text-align: right;
			.card-category {
				font-size: 16px;
				font-weight: 400;
				color: #9a9a9a;
				margin-bottom: 0;
				line-height: 30px;
			}
			.card-title {
				margin: 0;
				color: #333;
				font-weight: 300;
				font-size: 22px;
				line-height: 24px;
				font-weight: 400;
			}
		}
	}
	.card-footer {
		background: #ffffff;
		padding: 0 15px 10px;
		line-height: 30px;
		border-top: none !important;
		font-size: 14px;
		.stats {
			cursor: pointer;
			color: #a9a9a9;
			.material-icons {
				border-radius: 50%;
			}
			&:hover {
				color: blue;
				text-decoration: underline;
			}
		}
	}
}

/* Grow */
.grow {
	display: inline-block;
	transition-duration: 0.3s;
	transition-property: transform;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	transform: translateZ(0);
	&:hover {
		transform: scale(1.1);
	}
}
.line-height-10 {
	line-height: 10px;
}
.admin_request {
	.requestTagBadge {
		font-size: 90%;
		padding: 0.25rem !important;
		margin: 0.25rem !important;
		cursor: pointer;
		border: 1px solid #dee2e6 !important;
		display: inline-block;
		font-weight: 700;
		line-height: 1;
		text-align: center;
		white-space: nowrap;
		vertical-align: baseline;
		border-radius: 0.25rem;
		transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
			border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	}
	.requestTag {
		padding: 0px 8px;
		border: 1px solid #dcdfe4;
		margin-left: 5px;
		border-radius: 5px;
	}
	.card-body {
		flex: 1 1 auto;
		padding: 1rem 1.5rem;
	}
	.material-icons {
		font-size: 3em;
	}
	.bg-gradient-directional-danger {
		background-image: linear-gradient(45deg, #ff1635, #ff7c8d);
		background-repeat: repeat-x;
	}
	.bg-gradient-directional-success {
		background-image: linear-gradient(45deg, #20a576, #4eddaa);
		background-repeat: repeat-x;
	}
	.bg-gradient-directional-amber {
		background-image: linear-gradient(45deg, #ffa000, #ffd54f);
		background-repeat: repeat-x;
	}
	.bg-gradient-directional-info {
		background-image: linear-gradient(45deg, #0c84d1, #4eb4f5);
		background-repeat: repeat-x;
	}
}
.cursor-pointer {
	cursor: pointer !important;
}
.notificalion-alert {
	background-color: rgb(255, 255, 255);
	border-radius: 5px;
	width: 320px;
	padding: 5px;
	.footer {
		padding-left: 70px;
	}
	.requestDate {
		position: absolute;
		right: 42px;
		font-size: 13px;
		top: 10px;
	}
	.iclose {
		border: 10px;
		box-sizing: border-box;
		display: block;
		font-family: Roboto, sans-serif;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		cursor: pointer;
		position: absolute;
		height: 36px;
		top: 5px;
		right: 5px;
		background: none;
	}
}

.mapboxgl-popup {
	z-index: 99999;
}
