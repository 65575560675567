.mapbox-sign {
	border: 1px solid #e64823;
	padding: 0% 2%;
	font-size: 20px;
}

.mapbox-subitem {
	margin: 2% 0 2% 4%;
	height: auto;
	max-height: 243px;
	overflow-y: auto;
	padding: 2%;
}

// .mapbox-sidebar {
// 	height: 580px;
// 	overflow: auto;
// }

.mapbox-menu {
	font-size: 16px;
}

// .mapbox-menu-count {
//     font-size: 16px;
//     float: right;
//     border: 1px solid #E64823;
//     border-radius: 50%;
//     min-width: 35px;
//     width: auto;
//     padding: 1px;
//     text-align: center;
// }

.mapbox-hr {
	border: 2px solid #000;
}

.mapbox-custom-heading {
	font-size: 24px;
	margin-left: 27%;
}

.mapbox-btn-full {
	width: 100%;
}

.mapContainer {
	/*overflow: hidden;*/
	height: 580px;
}

.mapContainerAddProbing {
	/*overflow: hidden;*/
	height: 400px;
}

.changeMapStyle {
	position: absolute;
	padding: 5px;
	z-index: 1;
	top: 5px;
	left: 5px;
	border-radius: 3px;
	background-color: #fff;
}

.newlatlonginfo {
	position: absolute;
	padding: 5px;
	z-index: 1;
	bottom: 5px;
	background-color: #fff;
	right: 20px;
	opacity: 0.7;
	line-height: 11px;
	display: none;
	p {
		height: 10px;
		width: 10px;
		border-radius: 50%;
		display: inline-block;
		line-height: 11px;
	}
}

.pidInfo {
	position: absolute;
	padding: 5px;
	z-index: 1;
	bottom: 53px;
	background-color: #fff;
	left: 1rem;
	opacity: 0.9;
	line-height: 15px;
	display: none;
}

.areaMeasurementInfo {
	position: absolute;
	padding: 5px;
	z-index: 1;
	top: 10px;
	background-color: #fff;
	left: 20rem;
	opacity: 0.7;
	line-height: 11px;
	display: none;
}

.distanceMeasurementInfo {
	position: absolute;
	padding: 5px;
	z-index: 1;
	top: 10px;
	background-color: #fff;
	left: 0.8rem;
	opacity: 0.7;
	line-height: 11px;
	display: none;
}

.modal-open {
	overflow-y: auto !important;
}

body {
	overflow-y: auto !important;
}

.zoominfo {
	position: absolute;
	padding: 5px;
	z-index: 1;
	bottom: 0px;
	background-color: #fff;
	left: 120px;
	opacity: 0.5;
	line-height: 11px;
	display: none;
}

.extracls .custom-checkbox.custom-control {
	display: inline-block;
	margin-right: 5%;
}

.extracls .custom-radio.custom-control {
	display: inline-block;
	margin-right: 5%;
}

.extracls .custom-switch {
	display: inline-block;
	// margin-right: 5%;
	padding-top: 4.5%;
}

.marker_probings:hover {
	cursor: pointer;
}

.mapboxgl-popup {
	max-width: 100% !important;
}

.map-overlay {
	font: bold 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
	position: absolute;
	width: 25%;
	top: 50px;
	left: 20px;
}

.map-overlay .map-overlay-inner {
	background-color: #fff;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
	border-radius: 3px;
	padding: 10px;
	margin-bottom: 10px;
}

.map-overlay label {
	display: block;
	margin: 0 0 10px;
}

.map-overlay input {
	background-color: transparent;
	display: inline-block;
	width: 100%;
	position: relative;
	margin: 0;
	cursor: ew-resize;
}

.custome_marker {
	z-index: 10;
}

.go977458394 {
	display: flex !important;
}

.go1053659416 .dropdown-heading-value {
	display: flex;
}

.changeMapStyle_dashboard {
	position: absolute;
	padding: 5px;
	z-index: 1;
	background-color: #fff;
}

.custom-control-input:checked ~ .custom-control-label::before {
	color: #fff;
	background-color: #e64823;
	border-color: #e64823;
}

.form-control:focus {
	border-color: #e64823;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 0, 0, 0.6);
}

.mapButton {
	padding: 5px 17px !important;
}

.btn.disabled {
	color: #e64823 !important;
	border-color: #e64823 !important;
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
	background-color: #e64823 !important;
	opacity: 0.5 !important;
}

.adjustSpace {
	margin-right: 5px;
}

.righticons .custom-switch {
	display: inline-block;
	top: 8px;
}

.righticons .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
	background-color: #6c757d;
}

.myOpacityForPopup {
	opacity: 0.7;
	margin-top: -5px;
}

.margin-popup-label {
	margin-top: -20px;
}

.custom-control-label,
.custom-control-label::after {
	cursor: pointer;
}

.MuiSlider-root {
	color: #e64823 !important;
}

// custom style by jaydeep
.mapbox-sidebar {
	height: 100%;
	overflow: auto;
	.filter-items {
		// margin-bottom: 5px !important;
		margin: 5px 0px 8px 0px !important;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.left {
			width: 100%;
			display: flex;
			.mapbox-sign {
				font-size: 18px;
				margin-right: 5px;
				width: 20px;
				height: 20px;
				line-height: 18px;
			}
			.mapbox-menu {
				font-size: 15px;
			}
			small {
				margin: 5px;
			}
		}
		.right {
			display: flex;
			align-items: center;
			small {
				color: #000;
				font-size: 12px;
				margin-right: 10px;
				font-weight: 400;
			}
			.system-btn {
				margin-right: 5px;
				height: 20px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			.mapbox-menu-count {
				float: right;
				//width: auto;
				width: max-content;
				font-size: 12px;
				text-align: center;
				background: #e64823;
				color: #fff !important;
				min-width: auto !important;
				padding: 3px 5px;
				border-radius: 5px;
				line-height: 1.2;
				margin: 0 0 0 auto;
			}
		}
	}
	.mapbox-subitem {
		overflow-x: hidden;
		.MuiPaper-root {
			.MuiButtonBase-root {
				padding: 0 5px;
				.MuiExpansionPanelSummary-content {
					margin: 12px 0 12px 5px;
					& > div {
						& > p {
							.custom-checkbox {
								label {
									color: #000;
									font-weight: 500;
								}
							}
						}
					}
				}
			}
		}
		p {
			width: 100%;
			.filteritem-inner {
				display: flex;
				padding: 5px 0 5px 20px !important;
				&.filteritem-inner-pending {
					padding: 5px 0 5px 0px !important;
				}
				.custom-checkbox {
					.custom-control-label {
						display: block;
						max-width: 80px;
						width: 100%;
						font-weight: 400;
					}
				}
				label {
					line-height: 1.2;
					font-weight: 400;
				}
				& > span {
					height: 15px;
					width: 15px;
					display: inline-block;
					margin-top: 5px;
				}
				button {
					&:last-child {
						display: flex;
						align-items: center;
						justify-content: center;
						height: 25px;
						span {
							&.material-icons {
								width: 25px;
								font-size: 18px;
							}
						}
					}
				}
			}
		}

		.expansion-panel {
			padding: 0px !important;
			margin-bottom: 5px;
		}
	}
}

.materialIconsName {
	font-size: 17px;
}
